<template>
  <section>
    <b31 />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'B31'
      }
    },

    components: {
      B31: () => import('@/components/B31s.vue')
    }
  }
</script>
